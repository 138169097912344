exports.FRONT_END_CONST = {
    MIDDLEWARE_NODE_ENV:'UAT',//'PROD',// expected values PROD/UAT/LOCAL
    MIDDLEWARE_NODE_URL_LOCAL: 'http://localhost:8000/sfApi',
    MIDDLEWARE_NODE_URL_UAT: 'https://upi-bo-uat.nbcuni.com/sfApi',
    MIDDLEWARE_NODE_URL_PROD: 'https://upi-bo.nbcuni.com/sfApi',
    MAIN_HEADING1: "Universal Pictures International",
    MAIN_HEADING2: "Latest Box Office Reports",
    SUB_HEADING: 'Data last taken from Universal database at',
    QUOTES: 'The following is the reported box office position for each title, broken down by territory where UPI is the rights holder.',
    TABLE_HEADERS: {
        MAIN_TABLE: {
            LABELS: {
                TITLE_NAME: 'Title Name',
                RELEASE_DATE: 'First Intl Release Date',
                TOTAL_WKND_GROSS: 'Total Weekend Gross($)',
                UPI_GROSS: 'UPI Gross($)'
            }
        },

        POPUP_TABLE: {
            LABELS: {
                TERRITORY_NAME: 'Territory',
                RELEASE_DATE: 'Release Date',
                LATEST_BO_DATE: 'Latest BO Date',
                NUMBER_OF_WEEKS: '# of Weeks',
                NUMBER_OF_LOCATIONS: '# of Locations',
                PREVIEWS: 'Previews($)',
                PREVIEWS_LC: 'Previews(LC)',
                LATEST_WKND_BO: 'Latest Wknd BO($)',
                LATEST_WKND_BO_LC: 'Latest Wknd BO(LC)',
                CUMULATIVE_BO: 'Cumulative BO($)',
                CUMULATIVE_BO_LC: 'Cumulative BO(LC)',
            }

        },
        TOTAL_TABLE: {
            LABELS: {
                MAX_WEEKS: 'Max Weeks',
                TOTAL_LOCATIONS: 'Total Locations',
                TOTAL_PREVIEW: 'Total Preview($)',
                TOTAL_WKND_BO: 'Total Wknd BO($)',
                TOTAL_CUM_BO: 'Total Cumulative BO($)',
                TOTAL_LATEST_BO_WKND: 'Total Latest BO Wknd($)',
                TOTAL_GROSS_PRV: 'Total Gross Prv($)',

            }
        }

    }
}

